* {
    font-family: 'Franklin Gothic Medium', Tahoma, Geneva, Verdana, sans-serif;
    }
    :root {
        /* nuetrals */
        --gray: rgb(179, 180, 182); 
        --beauBlue: #BCD1E0;
        --Iris: #5A86A6;
        --lawnGray: #7C8A94;
        /* MONOCHROMATIC PALETTE */
        --blueLapis: #266E80;
        --persianBlue: #00A3CC;
        --feldgrauBlue: #4DDBFF;
        --PakistanGreen: #006680;
    
        /* SHADES PALETTE */
        --darkMidnightBlue: #003340;
        --BondiBlue: #0099BF;
        --cyan: #00B8E6;
        /* COMPOUND PALETTE */
        --iceblue: #00CCFF;
        --blue: #1447CC;
        --darkblue: #3D5699;
        --orange: #FF8040;
        --darkorange: #CC3714;
        /* ANALAGOUS PALETTE */
        --green: #0DFFAA;
        /* TRIAD PALETTE */
        --yellow: #FFEC19;
        /* GRADIENTS */
    
        /* CHANGE THE VARIABLE NAMES FROM ....GRADEIENT BLUES TO GRADIENT BLUEGRAY */
        --gradientblue: linear-gradient(to right, var(--beauBlue), var(--lawnGray));
        --revgradientblue: linear-gradient(to left, var(--beauBlue), var(--lawnGray));
        --centergradientblue: linear-gradient(to right, var(--lawnGray), var(--beauBlue), var(--lawnGray), var(--beauBlue));
        --gradientgray: linear-gradient(45deg, var(--lawnGray), var(--Iris));
        --gradientblueorange: linear-gradient(to right, var(--darkorange), var(--darkblue))
    }
    .banner {
        /* jumbotron style banner behind header*/
        width: 100%;
        /* height: 20em; */
        padding: 75px 0px 20px 0px;
        background: url("../images/Scoggins_highSchool_visit1_panorama_cropped1.jpeg");
        background-size: 1900px 350px;
        background-color: var(--darkblue);
        margin-bottom: 0px;
    }
    header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 300%;
        font-style: bold;
        text-align: center;
        padding: 35px 50px 35px 50px;
        border: 9px ridge var(--iceblue);
        border-radius: 10px;
        margin: 0px 200px 0px 200px;
        background: var(--gradientgray);
    }
    .customColorNav {
        background-color: var(--lawnGray);
    }
    /* Navbar has spacing around it, and does not cover up banner image */
    /* nav {
        display: flex;
        transition: flex-basis 500ms linear;
        font-size: 125%;
        margin-bottom: 1px;
        text-transform: uppercase;
    } */
    .navbar, .navcenter, .navright{
        display: flex;
        justify-content: center;
        width: 90%;
        padding: 15px 15px;
        margin: 25px 15px;
    }
    
    .navbar{
        text-align: center;
        align-items: center;
        padding: 8px 5px;
        border: 3px solid var(--blueLapis);
        background: var(--gradientblue);
        /* margin-top: 50px; */
    }
    .logo {
        margin-left: 10px;
    }
    /* different color gradient for 3rd link in nav bar */
    /* nav .navcenter {
        text-align: center;
        align-items: center;
        padding: 8px 5px;
        border: 3px solid var(--blueLapis);
        background: var(--centergradientblue);
        margin-top: 50px;
    } */
    /* different color gradient for 4th and 5th link in nav bar */
    /* nav .navright {
        width: 20%;
        text-align: center;
        align-items: center;
        padding: 8px 5px;
        border: 3px solid var(--blueLapis);
        background: var(--revgradientblue);
        margin-top: 50px;
    } */
    /* color style of hover from analagous pallete
    TODO: add other colors to analagous pallete and redo */
    .navbar:hover, .navcenter:hover, .navright:hover {
        font-style: italic;
        color: var(--green);
        background: var(--gradientblueorange);
        border: 4px dashed var(--iceblue);
    }
    .navbar:active, .navcenter:active, .navright:active {
        font-size: 85%;
        font-style: normal;
        font-style: bold;
        border: 8px double var(--darkblue);
        background: var(--gradientgray);
    }
    /* ADD NAVBAR:ACTIVE AND LBLINK:ACTIVE TO THIS */
    
    /* TODO: FORMAT THE ABOUT ME SECTION WITH H3 and with MEDIA QUERY */
    article {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
    }
    .aboutme {
        border: 9px inset var(--blueLapis);
        background: var(--centergradientblue);
        margin-left: 12px;
        margin-right: 12px;
        /* margin-top: 75px; */
        /* flex-direction: row; */
        /* flex-wrap: wrap; */
    }
    
    .headshot {
        /* justify-content: flex-start; */
        flex-wrap: wrap;
        width: 10%;
        height: 30%;
        float: right;
        border: 9px inset var(--blueLapis);
        margin: 20px 30px 20px 3px;
    }
    .aboutme h3, .aboutme p {
        /* border: 9px inset var(--orange); */
        /* background: var(--centergradientblue); */
        /* margin-right: 300px;
        margin-left: 25px; */
        padding: 10px 50px;
        margin: 5px 10px;
        background: transparent;
    }
    #coderefactor {
        height: 10em;
    }
    #passGen {
        width: 15em;
    }
    #work-day-scheduler {
        width: 15em;
    }
    #weather-dashboard {
        width: 15em;
    }
    #poke-weather-img {
        width: 15em;
    }
    h3 {
        font-size: 150%;
        
    }
    p {
        font-size: 150%;
        text-align: center;
    }
    .text-darken {
        color: var(--darkMidnightBlue)
    }
    .LBlink {
        background: transparent;
        color: var(--yellow);
    }
    .LBlink:hover {
        font-style: italic;
        color: var(--green);
        background: var(--gradientblueorange);
        border: 4px dashed var(--iceblue);
    }
    .LBlink:active {
        font-size: 115%;
        font-style: normal;
        font-style: bold;
        border: 8px double var(--darkblue);
        background: var(--gradientorange);
    }
    .main {
        display:flex;
        flex-wrap: wrap;
        transition: flex-basis 500ms linear;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;
    }

    .show {
        transform: translateX(0);
        opacity: 1;
    }
    a:hover{
        color: var(--darkorange)
    }
    
    h2 {
        text-align: center;
        padding: 20px 10px;
        border: groove 10px var(--iceblue);
        margin: 10px 20px 25px 20px;
    }
    
    .hw1 {
        width: 300px;
        height: 400px;
    }
    h3 {
        font-size: 150%;
        text-align: center;
    }
    
    .contact_form {
        display:flex;
        flex-wrap: wrap;
        transition: flex-basis 500ms linear;
        flex-direction: row;
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .contact_card {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 150%;
        margin: 10px 50px;
    
    }
    h4 {
        font-size: 300%;
        text-align: center;
        padding: 20px 10px;
        border: groove 10px var(--iceblue);
        margin: 10px 20px 25px 20px;
    }
    label { 
        flex-direction: column;
        background: var(--green)
    }
    input {
        background: var(--yellow)
    }
    .msginput{
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
    }
    li {
        list-style-type: none;
        display: flex;
        flex-direction: row;
    } 
    .customFooter {
        color: var(--beauBlue);
        background-color: var(--lawnGray);
        padding-top: 20px;
        overflow: hidden;
        min-height: 50px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 10px 0px;
    }

    .customColorFooter {
        color: var(--beauBlue);
        background-color: var(--lawnGray);
        
    }
